/* #projects{
    color: #495057;
    background-color: #212529;
} */
.projects-container {
    color: white;
    background-color: #191919;
    background: linear-gradient(180deg, black, #191919);
    width: 100%;
    padding: 2rem 1.25rem;
    margin: 0 auto;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.projects-header {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 2rem;
}

.projects-header i {
    font-size: 2.5em;
}

.projects-header h1 {
    font-size: 2em;
}

.projects-header p {
    font-size: 1.2em;
}

.gallery {
    display: flex;
    flex-wrap: wrap;
    margin: 1.5rem;
}

.thumbnail-link {
    width: 100%;
    padding: 1rem;
    text-decoration: none;
}

.thumbnail {
    display: flex;
    position: relative;
}

.proj-img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.proj-info {
    position: relative;
    padding: 2.5rem 2rem;
    z-index: 10;
    width: 100%;
    border-width: 4px;
    border-color: #343a40;
    background-color: #212529;
    opacity: 0;
    transition: .2s;
}

.proj-info:hover {
    opacity: 1;
}

.subtitle {
    letter-spacing: 0.1em;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    color: #61dafb;
    margin-bottom: 0.25rem;

}

.title {
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 500;
    color: white;
    margin-bottom: 0.75rem;
}

.description {
    line-height: 1.625;
    color: gray;
}

.live-link {
    display: inline-flex;
    color: black;
    background-color: #61dafb;
    border-width: 0;
    padding: 0.5rem 1.5rem;
    border-radius: 0.25rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
    text-decoration: none;
    margin: 0 .25rem;
    transition: .2s;
}

.live-link:hover {
    background-color: #caf0f8;
}

.github-link {
    display: inline-flex;
    color: white;
    background-color: #023e8a;
    border-width: 0;
    padding: 0.5rem 1.5rem;
    border-radius: 0.25rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
    text-decoration: none;
    margin: 0 .25rem;
    transition: .2s;
}

.github-link:hover {
    background-color: #03045e;
}

@media screen and (min-width: 640px) {
    .thumbnail-link {
        width: 50%;
    }
}
#skills {
    display: flex;
    flex-direction: column;
    background-color: #191919;
    background-size: cover;
    background-position: center;
    height: 100vh;
    top: 0;
}

.skills-container {
    color: white;
    width: 100%;
    padding: 2rem 1.25rem;
    margin: 0 auto;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.skills-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 2rem;
}

.skills-header i {
    font-size: 2.5em;
}

.skills-header h1 {
    font-size: 2em;
}

.skills-header p {
    font-size: 1.2em;
}

.skill-title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

}

.skills-grid {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 18em;
}

.skill-grid-area {
    background-color: #343a40;
    color: white;
    border: 1px solid transparent;
    border-radius: 5px;
    margin: 2em 2em;
    width: 8em;
    padding: 20px 90px;
}

.skill-title i {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 15px;
    color: #0077b6;
    background-color: aliceblue;
    width: 5px;
    height: 5px;
    padding: 10px;
    border: 1px solid transparent;
    border-radius: 50%;
}

.skill-title span {}

@media screen and (max-width: 1109px) {
    .skills-grid {
        /* display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap; */
        margin: 0 5em;
    }
}

@media screen and (max-width: 938px) {
    .skills-grid {
        /* display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap; */
        margin: 0 3em;
    }
}

@media screen and (max-width: 873px) {
    .skills-grid {
        /* display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap; */
        margin: 0 1em;
    }

    .skill-grid-area {
        margin: 1em 1em;
    }

    .skills-header i {
        font-size: 2em;
    }

    .skills-header h1 {
        font-size: 1.5em;
    }

    .skills-header p {
        font-size: 1em;
    }
}

@media screen and (max-width: 500px) {
    .skills-grid {
        /* display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap; */
        margin: 0 1em;
    }

    .skill-grid-area {
        margin: 0.5em 0.5em;
        padding: 10px 80px;
    }

    .skills-header {
        margin-bottom: 0;
    }
}
* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
}

.navbar {
    position: absolute;
    top: 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    background: transparent;
    animation: navload 500ms;
}

@keyframes navload {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0%);
    }
}

.brand {
    font-size: 1.5rem;
    margin: .5rem;
    text-decoration: none;
    color: white;
    border-bottom: solid transparent 3px;
    transition: .2s;
}

.navbar-links ul {
    display: flex;
    margin: 0;
    padding: 0;
}

.navbar-links li {
    list-style: none;
}

.navbar-links li a {
    text-decoration: none;
    color: white;
    padding: 1rem 1rem .2rem 1rem;
    margin: 1rem;
    display: block;
    border-bottom: solid transparent 3px;
    transition: .2s;
}

.navbar-links li a:hover {
    border-bottom: solid #8ecae6 3px;
}

.brand:hover {
    border-bottom: solid #8ecae6 3px;
}

.toggle-button {
    position: absolute;
    top: .75rem;
    right: 1rem;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 20px;
}

.toggle-button .bar {
    height: 3px;
    width: 100%;
    background-color: white;
    border-radius: 10px;
}

@media screen and (max-width: 600px) {
    .toggle-button {
        display: flex;
    }

    .navbar-links {
        display: none;
        width: 100%;
    }

    .navbar-links.active {
        display: flex;
    }

    .navbar-links li a {
        padding: .3rem 1rem;

    }

    .navbar {
        flex-direction: column;
        align-items: flex-start;
    }

    .navbar-links ul {
        flex-direction: column;
        width: 100%;
    }
}
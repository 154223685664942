#contact {
    position: relative;
}

.contact-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 2.5rem 7rem 2.5rem;
    margin: 0 auto;
    background-color: #191919;
}

.map-content {
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    width: 60%;
    border-radius: 0.5rem;
    padding: 2.5rem;
    border: 0;
    margin: 0;
}

.map-display {
    position: absolute;
    inset: 0px;
}

.map-info-container {
    background-color: #191919;
    color: white;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    padding: 1.5rem 0.5rem;
    border-radius: 0.25rem;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.map-info-container h2 {
    font-weight: 600;
    letter-spacing: 0.1em;
    font-size: 1rem;
    line-height: 1rem;
}

.map-info-container p {
    margin-top: 0.25rem;
    font-size: 0.75rem;
}

.map-info-container a {
    color: rgb(129 140 248);
    line-height: 1.625;
    font-size: 0.75rem;
}

.map-address {
    width: 100%;
    padding: 0 1rem;
}

.contact-form {
    width: 33%;
    margin-left: 3rem;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contact-form h2 {
    font-size: 45px;
}

.contact-form p {
    font-size: 15px;
    margin: 0 1.3rem 2rem 1.3rem;
    letter-spacing: 1px;
}

.name-entry {
    margin: 1rem 0;
}

.name-entry input {
    width: 100%;
    background-color: #343a40;
    color: white;
    border-width: 1px;
    border-color: #343a40;
    border-radius: 0.25rem;
    line-height: 1.75rem;
}

.name-entry input:focus {
    box-shadow: 0px 0px 2px 2px #6366f1;
    outline: none;
}

.name-entry label {
    float: left;
}

.email-entry {
    margin: 1rem 0;
}

.email-entry input {
    width: 100%;
    background-color: #343a40;
    color: white;
    border-width: 1px;
    border-color: #343a40;
    border-radius: 0.25rem;
    line-height: 1.75rem;
}

.email-entry input:focus {
    box-shadow: 0px 0px 2px 2px #6366f1;
    outline: none;
}

.email-entry label {
    float: left;
}

.message-entry {
    margin: 1rem 0;
}

.message-entry textarea {
    width: 100%;
    background-color: #343a40;
    color: white;
    border-width: 1px;
    border-color: #343a40;
    border-radius: 0.25rem;
    line-height: 1.75rem;
}

.message-entry textarea:focus {
    box-shadow: 0px 0px 2px 2px #6366f1;
    outline: none;
}

.message-entry label {
    float: left;
}

.submit-button {
    background-color: #6366f1;
    color: white;
    border-width: 1px;
    border-color: #6366f1;
    border-radius: 0.25rem;
    line-height: 1.75rem;
    transition: .2s;
}

.submit-button:hover {
    background-color: #999bf2;
}

@media screen and (max-width: 950px) {
    .map-content {
        padding: 2.5rem 1rem 2.5rem 1rem;
    }

    .map-content iframe {
        width: 75%;
        height: 100%;
    }

    .map-info-container {
        flex-direction: column;
        padding: 1.5rem 0.5rem;
        margin: 0 0 6rem 0;
    }

    .map-info-container h2 {
        font-weight: 600;
        letter-spacing: 0.1em;
        font-size: .8rem;
        line-height: .8rem;
    }

    .map-info-container p {
        margin-top: 0.25rem;
        font-size: 0.65rem;
    }

    .map-info-container a {
        font-size: 0.65rem;
    }

    .contact-form {
        width: 38%;
        color: white;
        margin: -2.65rem 0 0 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

}

@media screen and (max-width: 815px) {
    .contact-container {
        flex-direction: column;
        display: flex;
        align-items: center;
        width: 100%;
        padding-top: 5rem;
    }

    .map-content {
        width: 100%;
    }

    .map-content iframe {
        width: 100%;
    }

    .map-info-container {
        flex-direction: row;
        padding: 0.5rem;
        margin: 4rem 0 0 0;
    }

    .contact-form {
        margin: 0;
        width: 80%;
    }
}